import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

import CtaBanner from "../components/Home/cta-banner";
import StepsSection from "../components/Home/steps-section";
import Partners from "../components/Home/partners";
import Testimonials from "../components/Home/testimonials";
import RMWPCta from "../components/Home/rmwp-cta";

import { StaticImage } from "gatsby-plugin-image";

import Button from "../components/button";

import Spacer from "../components/spacer";

import {
  FaRegLightbulb,
  FaRegQuestionCircle,
  FaClinicMedical,
  FaRegEnvelope
} from "react-icons/fa";
import MailCta from "../components/Home/mail-cta";
import ContactForm from "../components/contactForm";
import ContactUsFormHomePageFork from "../components/contact-us-form-home-page-fork";

const IndexPage = () => {
  const tripanelData = [
    {
      header: "FAQ's",
      body:
        "Have additional questions? We've compiled our most common to get you the answers you need!",
      linkText: "Click here to learn more!",
      linkUrl: "/faq/",
      icon: (
        <FaRegQuestionCircle
          style={{ fill: "#4299E1", height: 48, width: 48 }}
        />
      )
    },
    {
      header: "Services",
      body:
        "We treat a number of health issues ranging from soft tissue conditions to men's sexual health.",
      linkText: "Learn more about how Multnomah Medical can help you!",
      linkUrl: "/mens-health/",
      icon: (
        <FaClinicMedical style={{ fill: "#4299E1", height: 48, width: 48 }} />
      )
    },
    {
      header: "The Science",
      body:
        "Acoustic wave therapy is a unique and breakthrough treatment that is approved by the FDA.",
      linkText: "Learn more about the science behind it.",
      linkUrl: "/science/",
      icon: (
        <FaRegLightbulb style={{ fill: "#4299E1", height: 48, width: 48 }} />
      )
    }
  ];

  //console.log(Cookies.get())
  return (
    <Layout hero>
      <SEO
        title="Multnomah Medical | Oregon's Premier Men's Health and Soft Tissue Clinic"
        description="At Multnomah Medical, we offer breakthrough soft-tissue therapy and sexual wellness treatments. We help patients without the need for any surgery, shots or pills."
      />
      <div className="flex-center-container text-center">
        <div className="max-width flex flex-col items-center mb-12">
          <h1 className="px-2 mt-12 text-2xl font-bold tracking-wide leading-normal">
            Providing Treatments for Neuropathy, Joint Pain, and Sexual
            Wellness.
          </h1>
          <Spacer />
          <div className="px-6 xs:px-12 sm:px-20 md:px-32 lg:max-w-4xl">
            <p
              key={"info10"}
              className="text-gray-900 text-lg leading-normal mb-6"
            >
              Multnomah Medical Clinic is the premier health clinic in the
              Portland area offering patients acoustic wave therapy, the latest
              innovation in soft-tissue and sexual wellness treatments. Acoustic
              wave works by delivering painless bursts of acoustic waves to the
              target region, which leads to a number of localized healing
              effects such as increased blood flow and reduced inflammation.
            </p>

            {/*<iframe
              className="w-full embed-vid-player"
              src="https://www.youtube.com/embed/TCt8Y_iwvnE"
              title="No More Joint Pain, No More Neuropathy. Soft Tissue Treatment Video"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
  ></iframe>*/}
         
            <p
              key={"info11"}
              className="text-gray-900 text-lg leading-normal mb-6"
            >
              We're the only clinic in the southwest Washington area utilizing
              both radial and focused shockwave therapy to treat a number of
              conditions including neuropathy, carpal tunnel syndrome, ED, and
              more. Best of all, our treatment is completely{" "}
              <span className="font-medium">
                needle free, surgery free and drug free
              </span>{" "}
            </p>
          </div>
          <div>
            <Button linkTo="/about/">Learn More</Button>
          </div>
        </div>
      </div>

      <StepsSection />
      <div className="w-full  justify-left px-4 md:px-12 pt-8">
       <div className="flex gap-2">
              <div>
                <FaRegEnvelope
                  className="mr-3 h-10 w-10"
                  style={{ fill: "black" }}
                />
              </div>
              <div>
                <h2 className="text-blue-500 text-left text-xl">
                  Book Your Appointment
                </h2>
                <p>Schedule directly through our form below.</p>
              </div>
              </div>
     

            <ContactUsFormHomePageFork />
            </div>
      <CtaBanner />
    
      <div className="w-full flex justify-center">
        <div className="pl-4 pr-4 mt-12 md:flex md:flex-row md:max-w-6xl justify-center content-center">
          {tripanelData.map((panel, index) => (
            <div
              key={"kpanel" + index}
              className="flex flex-col text-center px-8 sm:px-16 md:px-4 md:w-1/3 justify-center content-center mb-10"
            >
              <Link
                to={panel.linkUrl}
                className="flex flex-col items-center justify-center"
              >
                <div className="flex flex-col justify-center mb-1">
                  {panel.icon}
                </div>
                <h3 className="text-xl font-semibold mt-2 mb-2">
                  {panel.header}
                </h3>
              </Link>
              <p className="inline">
                {panel.body}
                <Link
                  className="text-blue-500 hover:text-blue-400 font-medium"
                  to={panel.linkUrl}
                >
                  {" " + panel.linkText}
                </Link>
              </p>
            </div>
          ))}
        </div>
      </div>
      <Testimonials />
      <div className="flex flex-col sm:flex-row bg-blue-400 p-8 sm:px-32 w-full justify-center">
        <div>
          <div className="font-bold text-black text-lg sm:text-md text-center mb-4">
            Sign up to get a free downloadable Ebook about the science and
            benefits of our treatment, extracorporeal shockwave therapy.
          </div>

          <div className="hidden sm:flex mb-2 pr-2 justify-center">
            <StaticImage
              src="../images/ebook.png"
              alt="The Art of Shock Wave Ebook"
              className="mx-1 mt-6"
              layout="fixed"
            />
            <MailCta />
          </div>

          <div className="flex flex-col justify-center sm:hidden w-full mb-4">
            <div className="w-full flex justify-center">
              <StaticImage
                src="../images/ebook.png"
                alt="The Art of Shock Wave Ebook"
                className="mx-1 mt-4 mb-4"
                layout="fixed"
              />
            </div>
            <MailCta />
          </div>
        </div>
        {/*<MailCta />*/}
      </div>
      <Partners />
    </Layout>
  );
};

export default IndexPage;
