import React from "React";
import Img from "gatsby-image";
import { StaticImage } from "gatsby-plugin-image";
import { graphql, Link, useStaticQuery } from "gatsby";
import Spacer from "../spacer";

const StepsSection = () => {
  const data = useStaticQuery(graphql`
    query StepImages {
      allFile(filter: { relativeDirectory: { eq: "home/stepsection" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(quality: 100, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      site {
        siteMetadata {
          appointmentProviderUrl
          financingProviderUrl
        }
      }
    }
  `);

  const { site, allFile } = data;

  const copy = [
    {
      header: "First Consultation.",
      alt: "man consulting with doctor",
      body:
        "Meet with a medical provider who will answer any questions and determine if the treatment is right for you.",
      picture: "mananddr",
      link: "/contact-us/"
    },
    {
      header: "Financing.",
      alt: "numbers being typed into calculator",
      body:
        "Need help paying your medical expenses? Visit our financing provider. Your insurance provider may cover this type of treatment.",
      picture: "calculator",
      link: site.siteMetadata.financingProviderUrl,
      outbound: true
    },
    {
      header: "Customized Plans.",
      alt: "couple holding hands",
      body:
        "We custom-tailor a treatment plan specifically for you. There is minimal discomfort and no recovery or down time.",
      picture: "handholding",
      link: "/contact-us/"
    }
  ];

  return (
    <div className="flex justify-center w-full text-center p-4 bg-gray-100">
      <div className="w-full">
        <h2 className="text-2xl font-bold mt-8 mb-2">
          Getting Started Is Quick And Easy
        </h2>
        <Spacer />
        <div className="flex justify-center mb-12 w-full">
          <div className="flex flex-wrap lg:flex-no-wrap w-full max-w-6xl">
            {copy.map((section, index) => {
              let imageToShow;
              if (section.picture === "3sandra") {
                imageToShow = (
                  <StaticImage
                    src="../../images/drheadshot.jpg"
                    alt="Woman leaning her head on man's shoulder and staring into ocean sunset"
                    className="w-full border-2 h-56 lg:h-48"
                    imgStyle={{ objectPosition: "50% 50%", objectFit: "cover" }}
                    placeholder="blurred"
                  ></StaticImage>
                );
              } else {
                imageToShow = (
                  <Img
                    fluid={
                      data.allFile.edges.filter(
                        x => x.node.name === section.picture
                      )[0]?.node.childImageSharp.fluid
                    }
                    className="w-full border-2 h-56 lg:h-48"
                    imgStyle={{ objectFit: "cover", objectPosition: "50% 0%" }}
                  />
                );
              }
              const innerContent = (
                <div className="hover:text-blue-600 hover:scale-105 transform transition-all ease-in duration-200">
                  {imageToShow}
                  <h3 className="uppercase font-semibold mt-3 mb-1">
                    {section.header.substring(0, section.header.length - 1)}
                  </h3>
                  <p>{section.body}</p>
                </div>
              );
              if (section.outbound) {
                return (
                  <a
                    href={section.link}
                    className="w-full sm:w-1/2 lg:w-1/3 mb-8 px-2"
                    target="_blank"
                    key={index}
                    rel="noopener noreferrer"
                  >
                    {innerContent}
                  </a>
                );
              } else {
                return (
                  <Link
                    to={section.link}
                    key={index}
                    className="w-full sm:w-1/2 lg:w-1/3 mb-8 px-2"
                  >
                    {innerContent}
                  </Link>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepsSection;
